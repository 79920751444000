// ** packages **
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Icon from "react-feather";

// ** components **
import SvgIcon from "components/Icon/SvgIcon";

// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import usePermission from "hooks/usePermission";
import { NavItem } from "./Sidebar";
import {
  getSidebarIsCollapse,
  setSidebarIsCollapse,
} from "redux/slices/commonSlice";
import { useDispatch } from "react-redux";

export type IconType =
  | "Settings"
  | "Grid"
  | "UserPlus"
  | "User"
  | "Users"
  | "Unlock";
type Props = {
  path: string;
  icon: string;
  label: string;
  currentPath?: string;
  subMenu?: {
    path: string;
    label: string;
    icon: string;
    permission?: {
      name: string;
      type: string;
    };
  }[];
  setIsSidebarClicked: React.Dispatch<React.SetStateAction<boolean>>;
};
type subMenuType = {
  path: string;
  label: string;
  icon: string;
  permission?: {
    name: string;
    type: string;
  };
};
type customPathType =
  | "notes"
  | "transcription"
  | "recording"
  | "setting"
  | "adminsetting";
const SidebarNavItem: React.FC<Props> = ({
  path,
  icon,
  label,
  currentPath,
  subMenu,
  setIsSidebarClicked,
}) => {
  const location = useLocation();
  const pathString = location.pathname;
  const locationState = location.state;
  const { hasPermission } = usePermission();
  const [isSubMenuOpen, setIsSubmenuOpen] = useState<boolean>(false);
  const linkOfActiveMenu = path;

  const submenuOpen = () => {
    if (isSubMenuOpen) {
      setIsSubmenuOpen(false);
    } else {
      setIsSubmenuOpen(true);
    }
  };

  const isCurrentlyActive = (path: string) => {
    const customPath = {
      notes: "/",
      transcription: "/patient",
      recording: "/",
      setting: "/setting/personalInformation",
      adminsetting: "/adminsetting/super-admin",
    };

    const customPathFromState: any = {
      transcription: {
        voice: PRIVATE_NAVIGATION.voice.view,
        content: PRIVATE_NAVIGATION.content.view,
        meeting: PRIVATE_NAVIGATION.MeetingFolder.view,
      },
      notes: {
        voice: PRIVATE_NAVIGATION.voice.view,
        content: PRIVATE_NAVIGATION.content.view,
        meeting: PRIVATE_NAVIGATION.MeetingFolder.view,
      },
      recording: {
        voice: PRIVATE_NAVIGATION.voice.view,
        content: PRIVATE_NAVIGATION.content.view,
        meeting: PRIVATE_NAVIGATION.MeetingFolder.view,
        patient: PRIVATE_NAVIGATION.patient.view,
      },
    };

    let isActive =
      linkOfActiveMenu === PRIVATE_NAVIGATION.dashboard.view
        ? location.pathname === linkOfActiveMenu
        : location.pathname.startsWith(
            path.substring(0, linkOfActiveMenu.length - 1)
          );

    const isActiveNoState =
      isActive ||
      path === customPath[location.pathname.split("/")[1] as customPathType];
    if (locationState) {
      const currentPath = customPathFromState[location.pathname.split("/")[1]];
      if (currentPath) {
        const activePath = currentPath[locationState?.navHighlighter];
        if (activePath) {
          return isActive || path === activePath;
        } else {
          return isActiveNoState;
        }
      } else {
        return isActiveNoState;
      }
    } else {
      return isActiveNoState;
    }
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const handleSidebarClicked = () => {
    setIsSidebarClicked(true);
    window.innerWidth < 780 && dispatch(setSidebarIsCollapse(false));
  };

  const checkActive = (subItem: subMenuType) => {
    if (pathString.slice(0, 13) === "/setting/user") {
      return pathString.slice(0, 13) === subItem.path;
    } else {
      return pathString === subItem.path;
    }
  };
  return (
    <div
      className={`s__Menu__Item ${isCurrentlyActive(path) ? "active" : ""}`}
      key={path}
    >
      {!subMenu ? (
        <Link
          to={path}
          className="s__Menu__Item__Link group cursor-pointer"
          onClick={() => {
            submenuOpen();
            handleSidebarClicked();
          }}
          key={label}
        >
          <div className="s__Menu__Icon !bg-transparent">
            <SvgIcon iconName={icon} className="" />
          </div>

          <div className="s__Menu__Text">{label}</div>
          {subMenu && (
            <div className="s__Menu__Arrow absolute top-[50%] translate-y-[-50%] -right-[9px]">
              {isSubMenuOpen ? <Icon.ChevronDown /> : <Icon.ChevronRight />}
            </div>
          )}
        </Link>
      ) : (
        <div
          className="s__Menu__Item__Link group cursor-pointer"
          onClick={submenuOpen}
          key={label}
        >
          <div className="s__Menu__Icon !bg-transparent">
            {<SvgIcon iconName={icon} className="" />}
          </div>

          <div className="s__Menu__Text">{label}</div>
          {subMenu && (
            <div className="s__Menu__Arrow absolute top-[50%] translate-y-[-50%] -right-[9px]">
              {isSubMenuOpen ? <Icon.ChevronDown /> : <Icon.ChevronRight />}
            </div>
          )}
        </div>
      )}

      <div className="submenu__wrapper pt-[6px]">
        {isSubMenuOpen &&
          subMenu?.map((item: subMenuType) => {
            const isActiveSubMenu = checkActive(item);
            const permitted =
              !item.permission || hasPermission(item.permission);
            return permitted ? (
              <Link
                onClick={handleSidebarClicked}
                to={item.path}
                className={`s__Menu__Item__Link submenu_item group mb-[6px] ${
                  isActiveSubMenu ? "active" : ""
                }`}
                key={item.path}
              >
                <div className="s__Menu__Icon !bg-transparent">
                  <SvgIcon iconName={item.icon} className="" />
                </div>
                <div
                  className={`s__Menu__Text ${
                    !isActiveSubMenu && "!text-black"
                  }`}
                >
                  {item.label}
                </div>
              </Link>
            ) : (
              <React.Fragment key={item.path} />
            );
          })}
      </div>
    </div>
  );
};
export default SidebarNavItem;
