import { memo, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Check } from "react-feather";
import {
  useGetAllSubscriptionPlans,
  useGetManagePlans,
  usePostSelectedPlan,
} from "../services";
import { convertTitleCase } from "utils";
import PageLoader from "components/Loaders/PageLoader";
import Breadcrumbs from "components/Breadcrumbs";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import Button from "components/Theme/Button";
import AlertModal from "components/modal/AlertModal";
import {
  planType,
  subscriptionPlansResponseType,
} from "../types/subscriptionTransactionTypes";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import { getAuth } from "redux/slices/authSlice";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const SubscriptionPlan = () => {
  const { user } = useSelector(getAuth);
  const {
    getManagePlans,
    isLoading: isManageLoading,
    isError: isManageError,
  } = useGetManagePlans();
  const [frequencies, setFrequencies] = useState<{
    selected: string;
    frequencies: string[];
  }>({
    selected: "",
    frequencies: [],
  });
  const {
    addSubscritionPlanApi,
    isLoading: isLoadingPost,
    isError,
  } = usePostSelectedPlan();
  const [subscriptionsAlert, setSubscriptionAlert] = useState<boolean>(false);

  const [planName, setPlanName] = useState<string>("");
  const [subscriptionsData, setSubscriptionData] =
    useState<subscriptionPlansResponseType>();

  const { getAllSubscriptionPlans, isLoading } = useGetAllSubscriptionPlans();

  const getSubscriptionPlansData = async () => {
    const { data } = await getAllSubscriptionPlans();
    const durations = Object.keys(data);
    setFrequencies({
      selected: durations?.[0] ?? "",
      frequencies: durations,
    });
    setSubscriptionData(data);
  };

  useEffect(() => {
    getSubscriptionPlansData();
  }, []);

  const addSubscritionPlanHandler = async (payload: { plan: string }) => {
    setSubscriptionAlert(true);
    const response = await addSubscritionPlanApi(payload);
    const data = response.data;
    if (!isError && data) {
      if (data.payment_url) {
        window.location = data.payment_url;
      }
    }
    //  else {
    //   navigate("/subscription/failed");
    // }
  };

  const selectedDuarationPlan = (option: string) => {
    setFrequencies({ ...frequencies, selected: option });
  };

  const alertSubmit = async () => {
    await addSubscritionPlanHandler({ plan: planName });
    setSubscriptionAlert(false);
  };

  const managePlansHandler = async () => {
    const { data } = await getManagePlans();
    if (!isManageError && data) {
      window.location.href = data;
    }
  };

  const currentPlanName = user?.current_plan?.payment_data?.plan?.plan_name;

  return (
    <div className="bg-white sm:py-32">
      <Breadcrumbs path={BREAD_CRUMB.SubscriptionPlans} />
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <AlertModal
            onCancel={() => setSubscriptionAlert(false)}
            onClose={() => setSubscriptionAlert(false)}
            onSubmit={alertSubmit}
            showFooter
            showCrossIcon
            visible={subscriptionsAlert}
            submitButtonText={currentPlanName ? "Yes" : "Buy"}
            submitLoading={isLoadingPost}
          >
            {currentPlanName ? (
              <div className="text-center">
                <p>
                  Do you want to switch from{" "}
                  <span className="text-green-600 font-bold">
                    {capitalize(currentPlanName)}
                  </span>{" "}
                  to{" "}
                  <span className="text-blue-600 font-bold">
                    {capitalize(planName)}
                  </span>
                  ?{" "}
                </p>
                {/* <ShowPaymentOptions /> */}
              </div>
            ) : (
              <div className="text-center">
                Do you want to buy this{" "}
                <span className="text-blue-700 font-bold">
                  {capitalize(planName)}
                </span>{" "}
                Plan?
              </div>
            )}
          </AlertModal>
          {subscriptionsData?.[frequencies?.selected]?.length && (
            <>
              <div className="mx-auto max-w-4xl my-[25px] text-center">
                <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-[21px] md:text-[30px]">
                  Subscription Plans
                </p>
              </div>
              <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Choose the plan that serves and enhance your professional
                requiments and boost your client satisfaction
              </p>
              <div className="mt-16 flex justify-center">
                {setSubscriptionData?.length > 1 && (
                  <fieldset aria-label="Payment frequency">
                    <RadioGroup
                      value={frequencies?.selected ?? undefined}
                      onChange={selectedDuarationPlan}
                      className="flex flex-wrap gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
                    >
                      {frequencies?.frequencies?.map((option) => (
                        <RadioGroup.Option
                          key={option}
                          value={option}
                          className="cursor-pointer rounded-full px-2.5 py-1 text-black aria-checked-[checked]:bg-indigo-600 aria-checked-[checked]:text-white"
                        >
                          <>{capitalize(option)}</>
                        </RadioGroup.Option>
                      ))}
                    </RadioGroup>
                  </fieldset>
                )}
              </div>
            </>
          )}
          <div className="isolate mt-10 flex flex-wrap  gap-8 justify-center">
            {subscriptionsData?.[frequencies?.selected]?.length ? (
              subscriptionsData?.[frequencies?.selected]?.map(
                (tier: planType) => (
                  <div
                    key={tier.id}
                    className={
                      currentPlanName === tier?.plan_name
                        ? classNames(
                            // tier.mostPopular
                            "border border-solid border-indigo-600",
                            "rounded-3xl p-8 w-1/4 lg:w-48 sm:w-full",
                            "bg-indigo-50"
                          )
                        : classNames(
                            "border border-solid border-indigo-600",
                            "rounded-3xl p-8 w-1/4 lg:w-48 sm:w-full"
                          )
                    }
                  >
                    <h3
                      id={`${tier.id}`}
                      className={classNames(
                        // tier.mostPopular
                        "text-indigo-600",
                        "text-lg font-semibold leading-8 flex flex-wrap-reverse justify-between"
                      )}
                    >
                      <p>{convertTitleCase(tier.plan_name)}</p>
                      <p className="bg-green-600 px-2 rounded-xl text-white text-[11px] self-center">
                        {currentPlanName === tier?.plan_name && (
                          <>Active Plan</>
                        )}
                      </p>
                    </h3>
                    <p className="mt-4 text-sm leading-6 text-gray-600">
                      {tier.description}
                    </p>
                    <p className="mt-6 flex flex-wrap items-baseline gap-x-1">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">
                        ${tier.price}
                      </span>
                      <span className="text-sm font-semibold leading-6 text-gray-600">
                        /{capitalize(frequencies?.selected)}
                      </span>
                    </p>

                    <Button
                      className={
                        !isManageLoading
                          ? classNames(
                              `${
                                currentPlanName === tier?.plan_name
                                  ? "bg-indigo-400"
                                  : "bg-indigo-600 hover:bg-indigo-500 cursor-pointer"
                              } text-white shadow-sm  mt-6 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full`
                            )
                          : classNames(
                              "bg-indigo-500 text-white shadow-sm mt-6 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer w-full"
                            )
                      }
                      onClick={() => {
                        if (currentPlanName !== tier?.plan_name) {
                          if (!isManageLoading) {
                            if (currentPlanName) {
                              setPlanName(tier?.plan_name);
                              managePlansHandler();
                            } else {
                              setPlanName(tier?.plan_name);
                              setSubscriptionAlert(true);
                            }
                            // if (currentPlanName === tier?.plan_name) {
                            //   setPlanName(tier?.plan_name);
                            //   managePlansHandler();
                            // } else {
                            //   setPlanName(tier?.plan_name);
                            //   setSubscriptionAlert(true);
                            // }
                          }
                        }
                      }}
                      loading={
                        planName === tier?.plan_name
                          ? isManageLoading
                            ? true
                            : isLoadingPost
                          : false
                      }
                    >
                      {/* {currentPlanName ? <>Manage Plan</> : <>Buy Plan</>} */}
                      {currentPlanName === tier?.plan_name ? (
                        <>Current Plan</>
                      ) : user?.current_plan ? (
                        <>Choose Plan</>
                      ) : (
                        <>Buy plan</>
                      )}
                    </Button>

                    <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                      {tier.specification.map((feature, idx) => (
                        <li key={feature.name} className="flex gap-x-3">
                          <Check
                            aria-hidden="true"
                            className="h-6 w-5 flex-none text-indigo-600"
                          />
                          {capitalize(feature.name)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )
            ) : (
              <div className="mt-[25%] flex">
                <p className="text-indigo-600 text-[30px] font-bold">
                  Sorry No Plans To Show At This Moment, Retry Again Later
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(SubscriptionPlan);
