// **  packages **
import { useEffect, useMemo, useState } from "react";
import { MoreVertical } from "react-feather";

// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";
import TippyDropdown from "components/TippyDropDown";
// ** helper **
import { renderOptions } from "components/TippyDropDown/helper";

// import { alertType } from "../types/usermanagment-types";
import { convertTitleCase, firstCharUpperCase } from "utils";
import { capitalize } from "lodash";
import { planType } from "modules/Setting/sub-modules/PaymentSetting/types/subscriptionTransactionTypes";

const useColRefInvoicesHistory = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1100 && windowSize < 1100) ||
      (window.innerWidth < 1100 && windowSize >= 1100)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const columnDefs = useMemo(
    () => [
      windowSize >= 1100 && {
        // sortField: "email",
        // sortable: true,
        name: "Customer",
        selector: (row: { user: { email: string } }) => row.user.email,
        cell: (row: { user: { email: string } }) => (
          <TableCellRender key={row.user.email} cellRowData={row.user.email} />
        ),
      },
      {
        name: "Plan Name",
        // sortField: "plan_name",
        // sortable: true,
        selector: (row: { plan: { plan_name: string } }) => row.plan.plan_name,
        cell: (row: { plan: { plan_name: string } }) => (
          <TableCellRender
            key={row.plan.plan_name}
            cellRowData={capitalize(row.plan.plan_name)}
          />
        ),
      },
      windowSize >= 1100 && {
        name: "Price",
        sortField: "price",
        sortable: true,
        selector: (row: { plan: planType }) => row.plan.price,
        cell: (row: { plan: planType }) => (
          <TableCellRender
            key={row.plan.price}
            cellRowData={`$${row.plan.price}`}
          />
        ),
      },
      {
        name: "Payment Status",
        sortField: "payment_status",
        selector: (row: { payment_status: boolean }) => row.payment_status,
        sortable: true,
        cell: (row: { id: number | string; payment_status: string }) => (
          <TableCellRender
            key={row?.id}
            className={
              row?.payment_status?.toLowerCase() !== "paid"
                ? "text-red-700"
                : "text-green-600"
            }
            cellRowData={row?.payment_status?.toLowerCase() === "processing" ? "Pending" : firstCharUpperCase(row?.payment_status)}
          />
        ),
      },
      {
        name: "Payment Date",
        selector: (row: { payment_date: string }) => row.payment_date,
        sortField: "payment_date",
        sortable: true,
        cell: (row: { payment_date: string }) => (
          <TableCellRender
            key={new Date(row?.payment_date)?.toLocaleDateString()}
            cellRowData={new Date(row?.payment_date)?.toLocaleDateString()}
          />
        ),
      },
      // {
      //   name: "Action",
      //   selector: (row: { id: string | number }) => row.id,
      //   sortable: false,
      //   cell: (row: {
      //     id: string | number;
      //     hosted_invoice_url: string;
      //     invoice_pdf_url: string;
      //   }) => {
      //     const userAdd: {
      //       label: string;
      //       onClick: () => void;
      //     }[] = [];

      //     userAdd.push({
      //       label: "View Invoice",
      //       onClick: () => {
      //         window.location.href = row.hosted_invoice_url;
      //       },
      //     });
      //     userAdd.push({
      //       label: "Download Invoice",
      //       onClick: () => {
      //         window.location.href = row.invoice_pdf_url;
      //       },
      //     });

      //     return (
      //       <TippyDropdown
      //         content={({ close }) =>
      //           renderOptions({ close, filedArray: userAdd })
      //         }
      //         className="cursor-pointer"
      //       >
      //         <MoreVertical className="cursor-pointer" />
      //       </TippyDropdown>
      //     );
      //   },
      // },
    ],
    [windowSize]
  );
  return columnDefs;
};

export default useColRefInvoicesHistory;
