import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuth } from "redux/slices/authSlice";
import { useGetManagePlans } from "../services";
import Button from "components/Theme/Button";
import { memo } from "react";

const CurrentPlan = () => {
  const { user } = useSelector(getAuth);
  const { getManagePlans, isLoading, isError } = useGetManagePlans();
  const navigator = useNavigate();

  const currentPlan = user?.current_plan;
  const paymentData = currentPlan?.payment_data;
  const planData = paymentData?.plan;
  let datetime = "";
  if (currentPlan?.current_period_end) {
    datetime = new Date(currentPlan?.current_period_end).toLocaleDateString();
  }

  const managePlansHandler = async () => {
    const { data } = await getManagePlans();
    if (!isError && data) {
      window.location.href = data;
    }
  };

  return (
    <>
      <div className="w-[100%] rounded-[10px] shadow-raiseShadow my-auto border-[1px]">
        <div className="justify-start border-b-[1px] p-2 rounded-t-lg">
          Current Plan
        </div>
        {currentPlan?.active ? (
          <div className="p-4 rounded-b-lg [&>*]:p-1 content-center">
            <div className="flex flex-wrap">
              <div className="flex ">
                <div className="mx-2 align-middle">
                  <p className="text-[30px] font-bold text-[#6558F5]">
                    $ {capitalize(planData?.price?.toFixed(2))}
                  </p>
                  <p>
                    <span className="text-sm">per</span>{" "}
                    <span className="text-lg font-semibold">
                      {capitalize(planData?.duration)}
                    </span>
                  </p>
                </div>
              </div>
              <div className="mx-8">
                <div className="text-[22px] font-bold">
                  <p>{capitalize(planData?.plan_name)}</p>
                </div>
                <div className="flex flex-wrap">
                  <div className="font-semibold">
                    {currentPlan?.active ? (
                      <>Renewal Date:&nbsp;&nbsp;</>
                    ) : (
                      <>Due Date:&nbsp;&nbsp;</>
                    )}
                  </div>
                  <div>{datetime}</div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-6 mb-2">
              <Button
                className="button__DSD bg-[#009900] cursor-pointer text-white md:mt-4 h-8"
                onClick={() => {
                  managePlansHandler();
                }}
                loading={isLoading}
              >
                Manage Plan
              </Button>
              <Button
                className="button__DSD bg-[#6558F5] cursor-pointer text-white md:mt-4 h-8"
                onClick={() => {
                  navigator("/setting/subscriptionplans");
                }}
              >
                Subscriptions
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-indigo-500">
            <div className="flex flex-col items-center justify-center h-48">
              <p>No Current Active Plan!</p>
              <p>
                Please Navigate To&nbsp;
                <Button
                  className="button__DSD bg-[#6558F5] cursor-pointer text-white mt-4 h-6 px-2"
                  onClick={() => {
                    navigator("/setting/subscriptionplans");
                  }}
                >
                  Buy Plan
                </Button>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(CurrentPlan);
