import SvgIcon from "components/Icon/SvgIcon";
import {
  useDeleteCard,
  useGetPaymentCardsDetails,
  usePostSelectDefaulCard,
} from "../services";
import { memo, useEffect, useState } from "react";
import { cardType } from "../types/subscriptionTransactionTypes";
import { Loader } from "react-feather";
import AlertModal from "components/modal/AlertModal";

const ShowPaymentOptions = () => {
  const [cardsData, setCardsData] = useState<cardType[]>([]);
  // const [deleteCard, setDeleteCard] = useState<number>(0);
  // const [cardDeleteAlert, setDeleteCardAlert] = useState<boolean>(false);
  const [cardDeleteData, setCardDeleteData] = useState<{
    id: null | number;
    open: boolean;
  }>({
    id: null,
    open: false,
  });

  const [cardDefaultData, setCardDefaultData] = useState<{
    id: null | number;
    open: boolean;
  }>({
    id: null,
    open: false,
  });
  const cardBrandsForSvg = [
    "visa",
    "american_express",
    "jcb",
    "discover",
    "mastercard",
    "unionpay",
    "union_pay",
    "diners_club",
  ];

  const { getCardsDetails, isLoading } = useGetPaymentCardsDetails();
  const { deleteCardApi, isLoading: isDeleteLoading } = useDeleteCard();
  const { setCardDefaultApi, isLoading: isLoadingPost } =
    usePostSelectDefaulCard();

  const fetchCardsDetails = async () => {
    const data = await getCardsDetails();
    if (data?.data?.length) {
      setCardsData(data?.data);
    }
  };

  const selectDefaultCardHandler = async (payload: { card_id: number }) => {
    const { error } = await setCardDefaultApi(payload);
    if (!error) {
      fetchCardsDetails();
    }
  };

  const deleteCardHandler = async (id: number) => {
    const { error } = await deleteCardApi(id);
    if (!error) {
      fetchCardsDetails();
    }
  };

  useEffect(() => {
    fetchCardsDetails();
  }, []);

  const alertSubmit = async () => {
    if (cardDefaultData?.id) {
      await selectDefaultCardHandler({ card_id: cardDefaultData?.id });
      setCardDefaultData({ ...cardDefaultData, open: false });
    }
  };

  const alertDeleteSubmit = async () => {
    if (cardDeleteData?.id) {
      await deleteCardHandler(cardDeleteData.id);
      setCardDeleteData({ ...cardDeleteData, open: false });
    }
  };

  return (
    <div className="w-[100%] rounded-[10px] shadow-raiseShadow my-4 border-[1px]">
      {/*  shadow-lg rounded-lg */}
      <div className="justify-start border-b-[1px] p-2 rounded-t-lg sticky">
        Payment Methods
      </div>

      <div className="h-48 overflow-auto px-2 py-1">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loader />
          </div>
        ) : (
          <>
            {cardsData?.length ? (
              <>
                <></>
                {cardsData
                  ?.sort((a, b) => +b.is_default - +a.is_default)
                  ?.map((data, i) => (
                    <div key={`card${i}`}>
                      <div className="flex flex-wrap text-[14px] mx-1 justify-between">
                        <div className="flex flex-wrap">
                          <div className="text-center content-center">
                            <SvgIcon
                              iconName={
                                cardBrandsForSvg.includes(
                                  data?.card_type?.toLowerCase()
                                )
                                  ? data?.card_type?.toLowerCase()
                                  : "credit-card"
                              }
                              className="h-[60px] w-[70px]"
                            />
                          </div>
                          <div className="col-span-4 text-start content-center p-2">
                            <p className="font-semibold">
                              **** **** **** {data?.card_number}
                              <>
                                {data?.is_default && (
                                  // <span className="px-4 bg-green-600 font-normal mx-2 text-[12px] bg-opacity-5 border-[1px] border-green-600 rounded-md">
                                  <span className="inline-flex items-center rounded-full truncate bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mx-2">
                                    Default
                                  </span>
                                )}
                              </>
                            </p>
                            <p className=" text-[11px]">
                              expires {data?.expiry_month}/{data?.expiry_year}
                            </p>
                          </div>
                        </div>
                        <div className="my-auto p-1 text-[12px] flex content-center align-middle">
                          {!data?.is_default && (
                            <p
                              onClick={() => {
                                setCardDefaultData({
                                  id: data?.id,
                                  open: true,
                                });
                              }}
                              // className="bg-indigo-600 px-3 py-1 rounded-lg hover:bg-indigo-500 cursor-pointer text-white"
                              className="button__DSD bg-[#6558F5] cursor-pointer text-white h-8 my-auto"
                            >
                              Set As Default
                            </p>
                          )}
                          <div className="p-2">
                            {!data?.is_default && cardsData.length > 1 ? (
                              <div
                                // className="cursor-pointer p-0 m-0 :hover"
                                className="relative cursor-pointer inline-flex items-center rounded-l-md ml-2 mr-2 bg-white px-3 py-2 text-sm font-semibold text-black hover:bg-indigo-100 hover:ring-primaryColor hover:rounded-lg focus:z-10"
                                onClick={() => {
                                  setCardDeleteData({
                                    id: data?.id,
                                    open: true,
                                  });
                                }}
                              >
                                <SvgIcon
                                  iconName="DeleteIconDark"
                                  className=""
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}{" "}
              </>
            ) : (
              <div className="text-indigo-500 flex justify-center mt-20">
                <p>No Card Details Are Available!</p>
              </div>
            )}
          </>
        )}
      </div>
      <AlertModal
        onCancel={() => {
          cardDeleteData?.id
            ? setCardDeleteData({ id: null, open: false })
            : setCardDefaultData({ id: null, open: false });
        }}
        onClose={() => {
          cardDeleteData?.id
            ? setCardDeleteData({ id: null, open: false })
            : setCardDefaultData({ id: null, open: false });
        }}
        onSubmit={cardDeleteData?.id ? alertDeleteSubmit : alertSubmit}
        showFooter
        showCrossIcon
        visible={
          cardDeleteData?.id ? cardDeleteData?.open : cardDefaultData?.open
        }
        submitButtonText="Yes"
        submitLoading={cardDeleteData?.id ? isDeleteLoading : isLoadingPost}
      >
        {cardDeleteData?.id ? (
          <p className="text-center">
            Are you sure you want to remove this Card ?
          </p>
        ) : (
          <p className="text-center">
            Do you wanted to change this card as Default ?
          </p>
        )}
      </AlertModal>
    </div>
  );
};

export default memo(ShowPaymentOptions);
