// ** packages **
import { useEffect, useMemo, useState } from "react";
import { MoreVertical } from "react-feather";
import { format as Format } from "date-fns";

// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";
import TippyDropdown from "components/TippyDropDown";
// ** helper **
import { renderOptions } from "components/TippyDropDown/helper";
// **  constant **
import { PERMISSIONS } from "constants/Permissions.constant";
import usePermission from "hooks/usePermission";
import { PromptColumnPropsType } from "../types/prompt.types";
import { firstCharUpperCase } from "utils";

// ** store **
import store from "redux/store";
import DateFormat from "components/DateFormat";

const PromptColumn = (props: PromptColumnPropsType) => {
  const { setIsOpen, onChangeStatus } = props;
  const storeData = store.getState();
  const is_super_user = storeData?.auth?.user?.is_super_administrator;
  const { hasPermission } = usePermission();
  const customPromptUpdateRole = hasPermission(
    PERMISSIONS.CUSTOM_PROMPT.UPDATE
  );
  const customPromptDeleteRole = hasPermission(
    PERMISSIONS.CUSTOM_PROMPT.DELETE
  );

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1100 && windowSize < 1100) ||
      (window.innerWidth < 1100 && windowSize >= 1100)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const columnDefs = useMemo(
    () => [
      windowSize >= 1300 ? {
        name: "Name",
        sortField: "name",
        // width: "12%",
        selector: (row: { name: string }) => row.name,
        sortable: true,
        cell: (row: { name: string | number; is_draft: boolean }) => (
          <TableCellRender
            key={row.name}
            cellRowData={
              <div className="flex items-center gap-2">
                {row.name}
                {row.is_draft && (
                  <p className="text-[12px] font-normal text-white bg-[#1ac01a] py-1 px-2 rounded-full w-fit inline-block whitespace-nowrap">
                    Draft
                  </p>
                )}
              </div>
            }
          />
        ),
      }: {
        name: "Name",
        selector: (row: { name: string }) => row?.name,
        sortable: true,
        sortField: "name",
        cell: (row: {
          name: string;
          created_at: string;
          created: { first_name: string; last_name: string };
          is_draft: boolean;
          is_deactivate:boolean;
        }) => (
          <TableCellRender
            className="flex overflow-hidden"
            cellRowData={
              <>
                <div className="ttttttt px-4 py-8 cursor-pointer">
                  <div className="flex gap-2 items-center">
                  {row.name}
                  {row.is_draft && (
                    <p className="text-[12px] font-normal text-white bg-[#1ac01a] py-1 px-2 rounded-full w-fit inline-block whitespace-nowrap">
                      Draft
                    </p>
                  )}
                    <span className="flex gap-2 text-gray-500">
                      {" "}
                      &#x2022;{" "}
                    </span>
                    <span
                      className={
                        !row.is_deactivate ? "text-sky-600 whitespace-nowrap" : "text-red-700 whitespace-nowrap"
                      }
                    >
                      {firstCharUpperCase(
                        !row.is_deactivate ? "Active" : "Inactive"
                      )}
                    </span>
                  </div>
                  <div className="lg:ml-0 lg:mt-1 break-all">
                    <div className="flex gap-2 items-center text-gray-500">
                      <span>
                        {row?.created ?
                          `Created by ${firstCharUpperCase(
                            row?.created?.first_name
                          )} ${firstCharUpperCase(
                            row?.created?.last_name
                          )}`: "System Default"}{" "}
                        &#x2022;{" "}
                        {row.created_at && (
                          <DateFormat
                            date={row.created_at}
                            format="dd MMM yyyy"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        ),
      },
      windowSize >= 1300 && {
        name: "Type",
        // width: "10%",
        sortField: "types",
        selector: (row: { types: string }) => row.types,
        sortable: true,
        cell: (row: { types: string }) => (
          <TableCellRender
            key={row.types}
            cellRowData={
              row.types === "summary"
                ? "Patient"
                : firstCharUpperCase(row.types)
            }
          />
        ),
      },
      // windowSize >= 1300 && {
      //   name: "Organization Name",
      //   // width: "12%",
      //   sortField: "organization.name",
      //   selector: (row: {
      //     organization_info: { name: string | null; id: number | null };
      //   }) => row?.organization_info?.name,
      //   sortable: false,
      //   cell: (row: {
      //     organization_info: { name: string | null; id: number | null };
      //   }) => (
      //     <TableCellRender
      //       key={row.organization_info?.name}
      //       cellRowData={row.organization_info?.name ?? "-"}
      //     />
      //   ),
      //   omit: !is_super_user,
      // },
      windowSize >= 1300 && {
        name: "Created Date",
        // width: "10%",
        sortField: "created_at",
        selector: (row: { created_at: string }) => row.created_at,
        sortable: true,
        cell: (row: { created_at: string }) => (
          <TableCellRender
            key={row.created_at}
            cellRowData={Format(new Date(row.created_at), "MM/dd/yyyy")}
          />
        ),
      },
      windowSize >= 1300 && {
        name: "Created by",
        // width: "12%",
        sortField: "email",
        selector: (row: { created: { email: string | null } }) =>
          row?.created?.email,
        sortable: false,
        cell: (row: { created: { email: string | null }, static: boolean | null }) => (
          <TableCellRender
            key={row.created?.email}
            cellRowData={row?.static ? "System Default" : (row?.created?.email || "-")}
          />
        ),
      },
      windowSize >= 1300 && {
        name: "Status",
        width: "10%",
        sortField: "is_deactivate",
        selector: (row: { is_deactivate: boolean }) => row.is_deactivate,
        sortable: false,
        cell: (row: {
          id: number | string;
          is_deactivate: boolean;
          is_draft: boolean;
        }) => (
          <TableCellRender
            key={row.id}
            className={row.is_deactivate ? "text-red-700" : "text-sky-600"}
            cellRowData={firstCharUpperCase(
              row.is_deactivate ? "Inactive" : "Active"
            )}
          />
        ),
      },
      {
        name: "Action",
        selector: (row: { id: number }) => row.id,
        sortable: false,
        width: windowSize >= 1300 && "10%",
        cell: (row: {
          id: number;
          static: boolean | null;
          is_deactivate: boolean;
          is_draft: boolean;
          is_deleted: boolean;
        }) => {
          const promptAdd: {
            label: string;
            onClick: () => void;
          }[] = [];

          ((row?.static && !is_super_user) && row.static) ? <></> : promptAdd.push({
            label:
              row.is_draft && customPromptUpdateRole
                ? "Save Prompt"
                : "View Prompt",
            onClick: () => {
              setIsOpen({
                open: true,
                id: row.id,
                isDefault: row?.static ?? false,
                viewOnly: row.is_draft ? !customPromptUpdateRole : true,
              });
            },
          });
          customPromptUpdateRole && (!row?.is_deleted) &&
            !row.is_draft &&
            promptAdd.push({
              label: row.is_deactivate ? "Active" : "Inactive",
              onClick: () => {
                onChangeStatus(
                  String(row.id),
                  "status",
                  row.is_deactivate ? "Active" : "Inactive",
                  row?.static
                );
              },
            });

          customPromptDeleteRole &&
            (!row?.static || is_super_user) && (!row?.is_deleted) &&
            promptAdd.push({
              label: "Delete Prompt",
              onClick: () => {
                onChangeStatus(row?.id, "delete", null, row?.static);
              },
            });
          return (
            <TippyDropdown
              content={({ close }) =>
                renderOptions({ close, filedArray: promptAdd })
              }
            >
              <MoreVertical />
            </TippyDropdown>
          );
        },
      },
    ],
    []
  );
  return columnDefs;
};

export default PromptColumn;




