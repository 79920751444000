import { RouteObjType } from "routes";
import {
  PRIVATE_NAVIGATION,
} from "constants/navigation.constant";
import AdminRoute from "./components/AdminRoute";
import React, { Suspense } from "react";
import PermissionGuard from "modules/Auth/components/PermissionGuard";
import { PERMISSIONS } from "constants/Permissions.constant";
import NotFoundPage from "modules/Auth/pages/NotFound";
import SuperAdminInvoices from "./sub-module/Setting/SuperUser/components/Invoices";

const AdminUser = React.lazy(() => import("./sub-module/AdminCustomer"));
const AdminDashboard = React.lazy(() => import("./sub-module/AdminDashboard"));
const CustomerDetail = React.lazy(
  () => import("./sub-module/AdminCustomer/components/CustomerDetail")
);
const PromptDetail = React.lazy(
  () => import("./sub-module/AdminPrompt/index")
);
const SuperUser = React.lazy(() => import("./sub-module/Setting/SuperUser"));
const NotFound404 = React.lazy(
  () => import("components/Theme/NoDataFound/NotFound")
);
const RoleAndPermissionSuperAdmin = React.lazy(
  () => import("./sub-module/Setting/RoleAndPermission")
);

export const applyAdminAuth = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: <AdminRoute>{route.element}</AdminRoute>,
  }));
};

const adminRoutes: RouteObjType[] = applyAdminAuth([
  {
    path: PRIVATE_NAVIGATION.adminDashboard.view,
    element: <AdminDashboard />,
  },
  {
    path: PRIVATE_NAVIGATION.customer.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.CUSTOMER.READ}>
        <AdminUser />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.customer.detail.view(),
    element: (
      <PermissionGuard permission={PERMISSIONS.CUSTOMER.READ}>
        <CustomerDetail />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminSetting.superuser.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.USER.READ}>
        <SuperUser />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminSetting.rolesAndPermissions.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.ROLE.READ}>
        <RoleAndPermissionSuperAdmin />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminSetting.invoices.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.USER.READ}>
        <SuperAdminInvoices />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminNotFoundPage,
    element: (
      <Suspense>
        <NotFound404 />
      </Suspense>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminPrompt.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.CUSTOM_PROMPT.READ}>
        <PromptDetail />
      </PermissionGuard>
    ),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
export default adminRoutes;
