import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const All_PLANS_BASE_PATH = "plans";

// ** custom get hooks
export const useGetAllSubscriptionPlans = () => {
  // ** custom hook for subscription
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getAllSubscriptionPlans = async () => {
    return callApi(`${All_PLANS_BASE_PATH}/all-plans`);
  };

  return { getAllSubscriptionPlans, isLoading, isError, isSuccess };
};

export const useGetCheckoutData = () => {
  // ** custom hook for get the checkout success data
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getCheckoutDataApi = async (checkoutSessionID: string) => {
    return callApi(
      `${All_PLANS_BASE_PATH}/get_subscription_detail?checkout_session_id=${checkoutSessionID}`
    );
  };

  return { getCheckoutDataApi, isLoading, isError, isSuccess };
};

export const useGetTransactionHistory = () => {
  // ** custom hook for get the checkout success data
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getTransactionHistory = async (data: object) => {
    return callApi(`${All_PLANS_BASE_PATH}/get_payment_history`, data);
  };

  return { getTransactionHistory, isLoading, isError, isSuccess };
};

export const useGetPaymentCardsDetails = () => {
  // ** custom hook for get card details
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getCardsDetails = async () => {
    return callApi(`${All_PLANS_BASE_PATH}/get_card_detail`);
  };

  return { getCardsDetails, isLoading, isError, isSuccess };
};

export const useGetManagePlans = () => {
  // ** custom hook for manageplans
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getManagePlans = async () => {
    return callApi(`${All_PLANS_BASE_PATH}/manage_plan`);
  };

  return { getManagePlans, isLoading, isError, isSuccess };
};

// ** custom post hooks
export const usePostSelectedPlan = () => {
  // ** custom hook for select subscription
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addSubscritionPlanApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(
      `${All_PLANS_BASE_PATH}/create-checkout-session`,
      data,
      config
    );
  };
  return { addSubscritionPlanApi, isLoading, isError, isSuccess };
};

export const usePostSelectDefaulCard = () => {
  // ** custom hook for setup the default payment card
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const setCardDefaultApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${All_PLANS_BASE_PATH}/set_card_default`, data, config);
  };
  return { setCardDefaultApi, isLoading, isError, isSuccess };
};

// ** custom put hooks
export const useDeleteCard = () => {
  // ** custom hook for delete card
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deleteCardApi = async (id: number | string) => {
    const response = await callApi(
      `${All_PLANS_BASE_PATH}/delete_card/${id}`,
      {}
    );
    return response;
  };

  return { deleteCardApi, isLoading, isError, isSuccess };
};
